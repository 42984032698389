import axiosInstance from "./api_helper";

const userService = {
    getUsers: async (params) => {
      const response = await axiosInstance.get('/api/users', { params });
      return response.data;
    },
    createUser: async (userData) => {
      await axiosInstance.post('/api/users', userData);
    },
    updateUser: async (userData) => {
      await axiosInstance.put(`/api/users/${userData.id}`, userData);
    },
  };
  
  export default userService;