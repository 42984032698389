import { createModule } from "saga-slice";
import { call, put } from "redux-saga/effects";
import { fetchProducteurs } from "../services/producteursService";

const producteursSlice = createModule({
  name: "producteurs",
  initialState: {
    producteurs: [],
    isFetching: false,
    error: null,
    totalPages: 0,
    currentPage: 0,
  },
  reducers: {
    list: (state) => {
      state.isFetching = true;
      state.error = null;
    },

    fetchedData: (state, payload) => {
      state.producteurs = payload.body.content;
      state.totalPages = payload.body.totalPages;
      state.currentPage = payload.body.pageNumber;
      state.isFetching = false;
    },

    finishFetching: (state) => {
      state.isFetching = false;
    },

    fetchError: (state, action) => {
      state.isFetching = false;
      state.error = action.payload || "An error occurred";
    },
  },

  sagas: (A) => ({
    *[A.list]({ payload }) {
      const {
        page = 0,
        size = 10,
        campagneId,
        codeProducteur,
        genre,
        districtId,
        regionId,
        prefectureId,
        sousPrefectureId,
        zoneId,
        faitiereId,
        cooperativeId,
        nationalite,
        cultureAgricoleId,
        localiteId,
      } = payload || {};

      try {
        const response = yield call(fetchProducteurs, {
          page,
          size,
          criteria: {
            campagneId,
            codeProducteur,
            genre,
            districtId,
            regionId,
            prefectureId,
            sousPrefectureId,
            zoneId,
            faitiereId,
            cooperativeId,
            nationalite,
            cultureAgricoleId,
            localiteId,
          },
        });

        console.log("API Response:", response);

        yield put(A.fetchedData(response.data));
      } catch (e) {
        console.error("Fetch Error:", e);
        yield put(
          A.fetchError(
            e.message || "An error occurred while fetching producteurs"
          )
        );
      } finally {
        yield put(A.finishFetching());
      }
    },
  }),
});

export default producteursSlice;
export const producteursActions = producteursSlice.actions;
