import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, FormGroup, Label, Container, Input, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { userActions } from '../../store/userSlice';

const UserForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const isEdit = Boolean(id);
    const { users } = useSelector((state) => state.user);
    const [initialValues, setInitialValues] = useState({
        nom: '',
        prenom: '',
        username: '',
        email: '',
        tel: '',
        password: '',
        roles: 'ROLE_AGENT',
        enabled: true,
    });

    useEffect(() => {
        if (isEdit) {
            dispatch(userActions.list({}));
            const user = users.find((u) => u.id === Number(id));
            if (user) {
                setInitialValues(user);
            }
        }
    }, [dispatch, id, isEdit, users]);

    const handleSubmit = (values) => {
        const rolesArray = values.roles ? [values.roles] : [];
        const updatedValues = { ...values, roles: rolesArray };

        if (isEdit) {
            dispatch(userActions.updateUser({ ...updatedValues, id: Number(id) }));
        } else {
            dispatch(userActions.createUser(updatedValues));
        }
        navigate('/gestion-utilisateurs');
    };

    return (
        <Container fluid className="mt-4">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item">
                        <Link to="/gestion-utilisateurs">Users</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        {isEdit ? 'Edit User' : 'Create User'}
                    </li>
                </ol>
            </nav>
            <h2 className="my-4">{isEdit ? 'Edit User' : 'Create User'}</h2>
            <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
                {({ values, handleChange }) => (
                    <Form>
                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="nom">Nom</Label>
                                    <Field name="nom" type="text" className="form-control" onChange={handleChange} value={values.nom} />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="prenom">Prenom</Label>
                                    <Field name="prenom" type="text" className="form-control" onChange={handleChange} value={values.prenom} />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="username">Username</Label>
                                    <Field name="username" type="text" className="form-control" onChange={handleChange} value={values.username} />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Field name="email" type="email" className="form-control" onChange={handleChange} value={values.email} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="tel">Tel</Label>
                                    <Field name="tel" type="text" className="form-control" onChange={handleChange} value={values.tel} />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="password">Password</Label>
                                    <Field name="password" type="password" className="form-control" onChange={handleChange} value={values.password} />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="roles">Roles</Label>
                                    <Input
                                        type="select"
                                        name="roles"
                                        value={values.roles}
                                        onChange={handleChange}
                                        className="form-control"
                                    >
                                        <option value="ROLE_ADMIN">Admin</option>
                                        <option value="ROLE_AGENT">Agent</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="enabled">Enabled</Label>
                                    <Field name="enabled" type="checkbox" onChange={handleChange} checked={values.enabled} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button type="submit" color="primary">{isEdit ? 'Update' : 'Create'}</Button>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default UserForm;
