import { combineReducers } from 'redux';

// Frontend layout
import Layout from './layout/reducer';

// Feature Modules
import producteursSlice from './producteursSlice';

// Root Reducer
import { rootReducer } from 'saga-slice';
import authModule from './authSlice';
import userModule from './userSlice';
import masterDataModule from './dataSlice';

// Define all saga-slice modules
const modules = [producteursSlice, authModule, userModule, masterDataModule];

// Combine reducers and modules
const appReducer = rootReducer(modules, {
  // Public reducers
  Layout,
});

// Optional: Add a root-level reducer to handle global state reset (e.g., on logout)
const rootReducerWrapper = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined; // Clear all state
  }
  return appReducer(state, action);
};

export default rootReducerWrapper;
