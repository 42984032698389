import axiosInstance from "./api_helper";

// Service function to fetch producteurs
export const fetchProducteurs = async ({ page, size, criteria }) => {
  return axiosInstance.post("/api/producteurs/search", {
    page,
    size,
    criteria,
  });
};
