import { createModule } from 'saga-slice';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchMasterData } from '../services/dataService';

const masterDataModule = createModule({
  name: "masterData",
  initialState: {
    data: {},
    isLoading: false,
    error: null,
  },
  reducers: {
    loadMasterDataRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    loadMasterDataSuccess: (state, payload) => {
      state.isLoading = false;
      state.data = payload;
    },
    loadMasterDataFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
  sagas: (A) => ({
    *[A.loadMasterDataRequest]({ payload }) {
      try {
        const response = yield call(fetchMasterData);
        yield put(A.loadMasterDataSuccess(response.body));
      } catch (error) {
        yield put(A.loadMasterDataFailure(error.message));
      }
    }
  }),
});
export const masterDataActions = masterDataModule.actions;
export default masterDataModule;