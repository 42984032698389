import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaMapMarkerAlt, FaInfoCircle } from "react-icons/fa"; // Import icons
import ReactSelect from "react-select";

import {
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { producteursActions } from "../../store/producteursSlice";
import config from "../../helpers/config";
import "../../assets/styles.css"; // Adjust the path to your CSS file

const DEFAULT_COORDINATES = { latitude: 5.309660, longitude: -4.012660 }; // Abidjan

const GestionProducteurs = () => {
  const dispatch = useDispatch();
  const { producteurs, isFetching, error, totalPages, currentPage } = useSelector(
    (state) => state.producteurs
  );
  const masterData = useSelector((state) => state.masterData.data);
  const [modal, setModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [mapModal, setMapModal] = useState(false);
  const [selectedCoordinates, setSelectedCoordinates] = useState({
    latitude: null,
    longitude: null,
  });

  const [selectedProducteur, setSelectedProducteur] = useState(null);
  const [detailsModal, setDetailsModal] = useState(false);

  // Toggle the modal
  const toggleModal = () => setModal(!modal);
  const toggleMapModal = () => setMapModal(!mapModal);
  const toggleDetailsModal = () => setDetailsModal(!detailsModal);

  // Local state for search form
  const [searchCriteria, setSearchCriteria] = useState({
    campagneId: "",
    codeProducteur: "",
    genre: "",
    districtId: "",
    regionId: "",
    perfectureId: "",
    sousPerfectureId: "",
    zoneId: "",
    faitiereId: "",
    cooperativeId: "",
    nationalite: "",
    cultureAgricoleId: "",
    localiteId: "",
  });

  // Handle search form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Trigger search
  const handleSearch = () => {
    dispatch(producteursActions.list({ page: 0, size: 10, ...searchCriteria }));
  };

  // Pagination handlers
  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      dispatch(producteursActions.list({ page: currentPage + 1, size: 10, ...searchCriteria }));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      dispatch(producteursActions.list({ page: currentPage - 1, size: 10, ...searchCriteria }));
    }
  };

  useEffect(() => {
    dispatch(producteursActions.list({ page: 0, size: 10 }));
  }, [dispatch]);

  // Map master data descriptions
  const cultureAgricoleDescription = (producteur) =>
    masterData?.culturesAgricoles?.find(
      (culture) => culture.id === producteur.cultureAgricoleId
    )?.nomCulture || "N/A";

  const districtDescription = (producteur) =>
    masterData?.districts?.find(
      (district) => district.id === producteur.districtId
    )?.description || "N/A";

  const regionDescription = (producteur) =>
    masterData?.regions?.find(
      (region) => region.id === producteur.regionId
    )?.description || "N/A";

  const prefectureDescription = (producteur) =>
    masterData?.prefectures?.find(
      (prefecture) => prefecture.id === producteur.prefectureId
    )?.nomPrefecture || "N/A";

  const sousPrefectureDescription = (producteur) =>
    masterData?.sousPrefectures?.find(
      (sousPrefecture) =>
        sousPrefecture.id === producteur.sousPrefectureId
    )?.nomSousPrefecture || "N/A";

  const zoneDescription = (producteur) =>
    masterData?.zones?.find((zone) => zone.id === producteur.zoneId)
      ?.description || "N/A";

  const cooperativeDescription = (producteur) =>
    masterData?.cooperatives?.find(
      (cooperative) => cooperative.id === producteur.cooperativeId
    )?.description || "N/A";

  const faitiereDescription = (producteur) =>
    masterData?.faitieres?.find(
      (faitiere) => faitiere.id === producteur.faitiereId
    )?.description || "N/A";

  const coordinates = {
    latitude: selectedProducteur?.latitude || DEFAULT_COORDINATES.latitude,
    longitude: selectedProducteur?.longitude || DEFAULT_COORDINATES.longitude,
  };

  return (
    <React.Fragment>
      {isFetching ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>
      ) :
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card>
                  <CardHeader className="text-white">
                    <h4 className="mb-0">Recherche des Producteurs</h4>
                  </CardHeader>
                  <CardBody>
                    {error && <Alert color="danger">{error}</Alert>}

                    <Form>
                      <Row className="mb-3 align-items-center">
                        <Col md="3">
                          <ReactSelect
                            name="campagneId"
                            id="campagneId"
                            value={masterData?.campagnes?.find(
                              (campagne) => campagne.id === searchCriteria.campagneId
                            )}
                            onChange={(selectedOption) => {
                              handleInputChange({
                                target: { name: "campagneId", value: selectedOption?.value },
                              });
                            }}
                            options={masterData?.campagnes?.map((campagne) => ({
                              value: campagne.id,
                              label: campagne.description,
                            }))}
                            placeholder="Select Campagne"
                            isClearable
                            styles={{
                              control: (base) => ({
                                ...base,
                                minHeight: '36px',
                                borderColor: '#ced4da',
                                boxShadow: 'none',
                              }),
                              placeholder: (base) => ({
                                ...base,
                                color: '#6c757d',
                              }),
                            }}
                          />
                        </Col>

                        <Col md="3">
                          <Input
                            type="text"
                            name="codeProducteur"
                            id="codeProducteur"
                            placeholder="Enter Code Producteur"
                            value={searchCriteria.codeProducteur}
                            onChange={handleInputChange}
                            style={{ height: '36px' }}
                          />
                        </Col>

                        <Col md="3">
                          <ReactSelect
                            name="genre"
                            id="genre"
                            value={[
                              { value: "", label: "Select Genre" },
                              { value: "HOMME", label: "Homme" },
                              { value: "FEMME", label: "Femme" },
                            ].find((option) => option.value === searchCriteria.genre)}
                            onChange={(selectedOption) => {
                              handleInputChange({
                                target: { name: "genre", value: selectedOption?.value },
                              });
                            }}
                            options={[
                              { value: "", label: "Select Genre" },
                              { value: "HOMME", label: "Homme" },
                              { value: "FEMME", label: "Femme" },
                            ]}
                            placeholder="Select Genre"
                            isClearable
                            styles={{
                              control: (base) => ({
                                ...base,
                                minHeight: '36px',
                                borderColor: '#ced4da',
                                boxShadow: 'none',
                              }),
                              placeholder: (base) => ({
                                ...base,
                                color: '#6c757d',
                              }),
                            }}
                          />
                        </Col>
                        <Col md="3">
                          <ReactSelect
                            name="districtId"
                            id="districtId"
                            value={masterData?.districts?.find(
                              (district) => district.id === searchCriteria.districtId
                            )}
                            onChange={(selectedOption) => {
                              handleInputChange({
                                target: { name: "districtId", value: selectedOption?.value },
                              });
                            }}
                            options={masterData?.districts?.map((district) => ({
                              value: district.id,
                              label: district.description,
                            }))}
                            placeholder="Select District"
                            isClearable
                            styles={{
                              control: (base) => ({
                                ...base,
                                minHeight: '36px',
                                borderColor: '#ced4da',
                                boxShadow: 'none',
                              }),
                              placeholder: (base) => ({
                                ...base,
                                color: '#6c757d',
                              }),
                            }}
                          />
                        </Col>

                      </Row>
                      <Row className="mb-3 align-items-center">

                        <Col md="3">
                          <ReactSelect
                            name="regionId"
                            id="regionId"
                            value={masterData?.regions?.find(
                              (region) => region.id === searchCriteria.regionId
                            )}
                            onChange={(selectedOption) => {
                              handleInputChange({
                                target: { name: "regionId", value: selectedOption?.value },
                              });
                            }}
                            options={masterData?.regions?.map((region) => ({
                              value: region.id,
                              label: region.description,
                            }))}
                            placeholder="Select Region"
                            isClearable
                            styles={{
                              control: (base) => ({
                                ...base,
                                minHeight: '36px',
                                borderColor: '#ced4da',
                                boxShadow: 'none',
                              }),
                              placeholder: (base) => ({
                                ...base,
                                color: '#6c757d',
                              }),
                            }}
                          />
                        </Col>
                        <Col md="3">
                          <ReactSelect
                            name="perfectureId"
                            id="perfectureId"
                            value={masterData?.perfectures?.find(
                              (perfe) => perfe.id === searchCriteria.perfectureId
                            )}
                            onChange={(selectedOption) => {
                              handleInputChange({
                                target: { name: "perfectureId", value: selectedOption?.value },
                              });
                            }}
                            options={masterData?.perfectures?.map((perfe) => ({
                              value: perfe.id,
                              label: perfe.nomPrefecture,
                            }))}
                            placeholder="Select Préfecture"
                            isClearable
                            styles={{
                              control: (base) => ({
                                ...base,
                                minHeight: '36px',
                                borderColor: '#ced4da',
                                boxShadow: 'none',
                              }),
                              placeholder: (base) => ({
                                ...base,
                                color: '#6c757d',
                              }),
                            }}
                          />
                        </Col>
                        <Col md="3">
                          <ReactSelect
                            name="sousPerfectureId"
                            id="sousPerfectureId"
                            value={masterData?.sousPerfectures?.find(
                              (perfe) => perfe.id === searchCriteria.sousPerfectureId
                            )}
                            onChange={(selectedOption) => {
                              handleInputChange({
                                target: { name: "sousPerfectureId", value: selectedOption?.value },
                              });
                            }}
                            options={masterData?.sousPerfectures?.map((perfe) => ({
                              value: perfe.id,
                              label: perfe.nomSousPrefecture,
                            }))}
                            placeholder="Select Sous Préfecture"
                            isClearable
                            styles={{
                              control: (base) => ({
                                ...base,
                                minHeight: '36px',
                                borderColor: '#ced4da',
                                boxShadow: 'none',
                              }),
                              placeholder: (base) => ({
                                ...base,
                                color: '#6c757d',
                              }),
                            }}
                          />
                        </Col>
                        <Col md="3">
                          <ReactSelect
                            name="localiteId"
                            id="localiteId"
                            value={masterData?.villages?.find(
                              (perfe) => perfe.id === searchCriteria.localiteId
                            )}
                            onChange={(selectedOption) => {
                              handleInputChange({
                                target: { name: "localiteId", value: selectedOption?.value },
                              });
                            }}
                            options={masterData?.villages?.map((perfe) => ({
                              value: perfe.id,
                              label: perfe.description,
                            }))}
                            placeholder="Select Localité"
                            isClearable
                            styles={{
                              control: (base) => ({
                                ...base,
                                minHeight: '36px',
                                borderColor: '#ced4da',
                                boxShadow: 'none',
                              }),
                              placeholder: (base) => ({
                                ...base,
                                color: '#6c757d',
                              }),
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3 align-items-center">

                        <Col md="3">
                          <ReactSelect
                            name="faitiereId"
                            id="faitiereId"
                            value={masterData?.faitieres?.find(
                              (perfe) => perfe.id === searchCriteria.faitiereId
                            )}
                            onChange={(selectedOption) => {
                              handleInputChange({
                                target: { name: "faitiereId", value: selectedOption?.value },
                              });
                            }}
                            options={masterData?.faitieres?.map((perfe) => ({
                              value: perfe.id,
                              label: perfe.description,
                            }))}
                            placeholder="Select Faitière"
                            isClearable
                            styles={{
                              control: (base) => ({
                                ...base,
                                minHeight: '36px',
                                borderColor: '#ced4da',
                                boxShadow: 'none',
                              }),
                              placeholder: (base) => ({
                                ...base,
                                color: '#6c757d',
                              }),
                            }}
                          />
                        </Col>
                        <Col md="3">
                          <ReactSelect
                            name="cooperativeId"
                            id="cooperativeId"
                            value={masterData?.cooperatives?.find(
                              (perfe) => perfe.id === searchCriteria.cooperativeId
                            )}
                            onChange={(selectedOption) => {
                              handleInputChange({
                                target: { name: "cooperativeId", value: selectedOption?.value },
                              });
                            }}
                            options={masterData?.cooperatives?.map((perfe) => ({
                              value: perfe.id,
                              label: perfe.description,
                            }))}
                            placeholder="Select Coopératives"
                            isClearable
                            styles={{
                              control: (base) => ({
                                ...base,
                                minHeight: '36px',
                                borderColor: '#ced4da',
                                boxShadow: 'none',
                              }),
                              placeholder: (base) => ({
                                ...base,
                                color: '#6c757d',
                              }),
                            }}
                          />
                        </Col>
                        <Col md="3">
                          <ReactSelect
                            name="zoneId"
                            id="zoneId"
                            value={masterData?.zones?.find(
                              (perfe) => perfe.id === searchCriteria.zoneId
                            )}
                            onChange={(selectedOption) => {
                              handleInputChange({
                                target: { name: "zoneId", value: selectedOption?.value },
                              });
                            }}
                            options={masterData?.zones?.map((perfe) => ({
                              value: perfe.id,
                              label: perfe.description,
                            }))}
                            placeholder="Select Zone"
                            isClearable
                            styles={{
                              control: (base) => ({
                                ...base,
                                minHeight: '36px',
                                borderColor: '#ced4da',
                                boxShadow: 'none',
                              }),
                              placeholder: (base) => ({
                                ...base,
                                color: '#6c757d',
                              }),
                            }}
                          />
                        </Col>
                        <Col md="3">
                          <ReactSelect
                            name="sectionId"
                            id="sectionId"
                            value={masterData?.sections?.find(
                              (perfe) => perfe.id === searchCriteria.sectionId
                            )}
                            onChange={(selectedOption) => {
                              handleInputChange({
                                target: { name: "sectionId", value: selectedOption?.value },
                              });
                            }}
                            options={masterData?.sections?.map((perfe) => ({
                              value: perfe.id,
                              label: perfe.nomSection,
                            }))}
                            placeholder="Select Section"
                            isClearable
                            styles={{
                              control: (base) => ({
                                ...base,
                                minHeight: '36px',
                                borderColor: '#ced4da',
                                boxShadow: 'none',
                              }),
                              placeholder: (base) => ({
                                ...base,
                                color: '#6c757d',
                              }),
                            }}
                          />
                        </Col>
                      </Row>



                      <Row className="mb-3 align-items-center">




                        <Col md="3" className="d-flex align-items-center">
                          <Button
                            color="primary"
                            onClick={handleSearch}
                            disabled={isFetching}
                            style={{ height: '36px', width: '100%', marginRight: '10px' }}
                          >
                            {isFetching ? <Spinner size="sm" /> : "Rechercher"}
                          </Button>
                          <Button
                            color="secondary"
                            onClick={() => {
                              setSearchCriteria({
                                campagneId: "",
                                codeProducteur: "",
                                genre: "",
                                districtId: "",
                                regionId: "",
                                perfectureId: "",
                                sousPerfectureId: "",
                                zoneId: "",
                                faitiereId: "",
                                cooperativeId: "",
                                nationalite: "",
                                cultureAgricoleId: "",
                                localiteId: "",
                              });
                              // Load initial data with no search criteria
                              dispatch(producteursActions.list({ page: 0, size: 10 }));
                            }}
                            style={{ height: '36px', width: '100%' }}
                          >
                            Effacer
                          </Button>
                        </Col>
                      </Row>


                    </Form>

                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col lg="12">
                <Card>
                  <CardBody>
                    {isFetching && (
                      <div className="text-center">
                        <Spinner color="primary" />
                      </div>
                    )}
                    {!isFetching && (
                      <Table striped responsive>
                        <thead>
                          <tr>
                            <th>Photo</th>
                            <th>Code Producteur</th>
                            <th>Date d'enregistrement</th>
                            <th>Nom Prenoms</th>
                            <th>Genre</th>
                            <th>Culture Agricole</th>
                            <th>District</th>
                            <th>Region</th>
                            <th>Prefecture</th>
                            <th>Sous-Prefecture</th>
                            <th>Zone</th>
                            <th>Cooperative</th>
                            <th>Faitiere</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {producteurs.length > 0 ? (
                            producteurs.map((producteur) => {

                              const coordinates = {
                                latitude: producteur.latitude || DEFAULT_COORDINATES.latitude,
                                longitude: producteur.longitude || DEFAULT_COORDINATES.longitude,
                              };
                              console.log(`${config.baseURL}${producteur.imageServerUrl}`)
                              return (
                                <tr key={producteur.id}>
                                  <td>
                                    <img
                                      src={`${config.baseURL}${producteur.imageServerUrl}`}
                                      alt="Producteur"
                                      className="img-thumbnail"
                                      style={{ width: "50px", cursor: "pointer" }}
                                      onClick={() => {
                                        setSelectedImage(
                                          `${config.baseURL}${producteur.imageServerUrl}`
                                        );
                                        toggleModal();
                                      }}
                                    />
                                  </td>
                                  <td>{producteur.codeProducteur}</td>
                                  <td>{producteur.createdDate}</td>
                                  <td>{producteur.nomPrenoms}</td>
                                  <td>{producteur.genre}</td>
                                  <td>{cultureAgricoleDescription(producteur)}</td>
                                  <td>{districtDescription(producteur)}</td>
                                  <td>{regionDescription(producteur)}</td>
                                  <td>{prefectureDescription(producteur)}</td>
                                  <td>{sousPrefectureDescription(producteur)}</td>
                                  <td>{zoneDescription(producteur)}</td>
                                  <td>{cooperativeDescription(producteur)}</td>
                                  <td>{faitiereDescription(producteur)}</td>
                                  <td style={{ display: "flex", gap: "8px", justifyContent: "center" }}>
                                    <Button
                                      color="primary"
                                      size="sm"
                                      onClick={() => {
                                        setSelectedCoordinates(coordinates);
                                        toggleMapModal();
                                      }}
                                    >
                                      <FaMapMarkerAlt />
                                    </Button>
                                    <Button
                                      color="info"
                                      size="sm"
                                      onClick={() => {
                                        setSelectedProducteur(producteur);
                                        toggleDetailsModal();
                                      }}
                                    >
                                      <FaInfoCircle />
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="12" className="text-center">
                                No producteurs found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    )}

                    {/* Modal for Image Zoom */}
                    <Modal isOpen={modal} toggle={toggleModal} centered>
                      <ModalBody>
                        <img
                          src={selectedImage}
                          alt="Zoomed Producteur"
                          className="img-fluid"
                        />
                      </ModalBody>
                    </Modal>
                    {/* Map Modal */}
                    <Modal isOpen={mapModal} toggle={toggleMapModal} className="modal-custom-size" // Custom size class
                      // Add this class
                      centered>
                      <ModalHeader toggle={toggleMapModal}>
                        Geolocation of Producteur
                      </ModalHeader>
                      <ModalBody className="modal-custom-body" >
                        {selectedCoordinates.latitude && selectedCoordinates.longitude ? (
                          <iframe
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            style={{ border: 0 }}
                            src={`https://www.google.com/maps?q=${selectedCoordinates.latitude},${selectedCoordinates.longitude}&hl=es;z=14&output=embed`}
                            allowFullScreen
                            title="Google Map"
                          ></iframe>
                        ) : (
                          <p>Coordinates not available</p>
                        )}
                      </ModalBody>
                    </Modal>

                    {/* Details Modal */}
                    <Modal
                      isOpen={detailsModal}
                      toggle={toggleDetailsModal}
                      className="modal-custom-size"
                      centered
                    >
                      <ModalHeader toggle={toggleDetailsModal}>Producteur Details</ModalHeader>
                      <ModalBody className="modal-custom-body">
                        {selectedProducteur ? (
                          <div>
                            <Row>
                              {/* Image Column */}
                              <Col md="2" className="d-flex">
                                <img
                                  src={`${config.baseURL}${selectedProducteur.imageServerUrl}`}
                                  alt="Producteur"
                                  className="img-fluid rounded"
                                  style={{ height: "200px", width: "200px", objectFit: "cover" }}
                                />
                              </Col>

                              {/* Details Columns (Spanning 3 Rows) */}
                              <Col md="9">
                                <Row>
                                  <Col md="3">
                                    <Label>Code Producteur</Label>
                                    <Input type="text" value={selectedProducteur.codeProducteur} disabled />
                                  </Col>
                                  <Col md="3">
                                    <Label>Nom Prenoms</Label>
                                    <Input type="text" value={selectedProducteur.nomPrenoms} disabled />
                                  </Col>
                                  <Col md="3">
                                    <Label>Genre</Label>
                                    <Input type="text" value={selectedProducteur.genre} disabled />
                                  </Col>
                                  <Col md="3">
                                    <Label>Date de Naissance</Label>
                                    <Input
                                      type="text"
                                      value={
                                        selectedProducteur.dateNaissance
                                          ? new Date(selectedProducteur.dateNaissance).toLocaleDateString()
                                          : "N/A"
                                      }
                                      disabled
                                    />
                                  </Col>
                                  <Col md="3">
                                    <Label>Lieu de Naissance</Label>
                                    <Input
                                      type="text"
                                      value={selectedProducteur.lieuNaissance || "N/A"}
                                      disabled
                                    />
                                  </Col>
                                  <Col md="3">
                                    <Label>Nom Père</Label>
                                    <Input type="text" value={selectedProducteur.nomPere || "N/A"} disabled />
                                  </Col>
                                  <Col md="3">
                                    <Label>Nom Mère</Label>
                                    <Input type="text" value={selectedProducteur.nomMere || "N/A"} disabled />
                                  </Col>
                                  <Col md="3">
                                    <Label>Numéro Téléphone</Label>
                                    <Input
                                      type="text"
                                      value={selectedProducteur.numeroTelephone || "N/A"}
                                      disabled
                                    />
                                  </Col>
                                  <Col md="3">
                                    <Label>Culture Agricole</Label>
                                    <Input
                                      type="text"
                                      value={cultureAgricoleDescription(selectedProducteur)}
                                      disabled
                                    />
                                  </Col>
                                  <Col md="3">
                                    <Label>Localité</Label>
                                    <Input
                                      type="text"
                                      value={selectedProducteur.localite?.description || "N/A"}
                                      disabled
                                    />
                                  </Col>
                                  <Col md="3">
                                    <Label>District</Label>
                                    <Input
                                      type="text"
                                      value={selectedProducteur.district?.description || "N/A"}
                                      disabled
                                    />
                                  </Col>
                                  <Col md="3">
                                    <Label>Région</Label>
                                    <Input
                                      type="text"
                                      value={selectedProducteur.region?.description || "N/A"}
                                      disabled
                                    />
                                  </Col>
                                  <Col md="3">
                                    <Label>Préfecture</Label>
                                    <Input
                                      type="text"
                                      value={selectedProducteur.prefecture?.description || "N/A"}
                                      disabled
                                    />
                                  </Col>
                                  <Col md="3">
                                    <Label>Sous-Préfecture</Label>
                                    <Input
                                      type="text"
                                      value={selectedProducteur.sousPrefecture?.description || "N/A"}
                                      disabled
                                    />
                                  </Col>
                                  <Col md="3">
                                    <Label>Zone</Label>
                                    <Input
                                      type="text"
                                      value={selectedProducteur.zone?.description || "N/A"}
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            {/* Geolocation Section */}
                            <Row className="mt-4">
                              <Col xs="12">
                                <h5>Geolocation</h5>
                                {coordinates.latitude && coordinates.longitude ? (
                                  <iframe
                                    width="100%"
                                    height="300"
                                    frameBorder="0"
                                    style={{ border: 0 }}
                                    src={`https://www.google.com/maps?q=${coordinates.latitude},${coordinates.longitude}&hl=es;z=14&output=embed`}
                                    allowFullScreen
                                    title="Google Map"
                                  ></iframe>
                                ) : (
                                  <p>Geolocation not available for this producteur.</p>
                                )}
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          <p>No details available</p>
                        )}
                      </ModalBody>
                    </Modal>

                    {/* Pagination Controls */}
                    <div className="d-flex justify-content-between">
                      <Button
                        color="primary"
                        disabled={currentPage === 0 || isFetching}
                        onClick={handlePreviousPage}
                      >
                        Previous
                      </Button>
                      <Pagination aria-label="Page navigation">
                        <PaginationItem disabled={currentPage === 0}>
                          <PaginationLink
                            previous
                            onClick={handlePreviousPage}
                            disabled={currentPage === 0 || isFetching}
                          />
                        </PaginationItem>
                        <PaginationItem disabled={currentPage >= totalPages - 1}>
                          <PaginationLink
                            next
                            onClick={handleNextPage}
                            disabled={currentPage >= totalPages - 1 || isFetching}
                          />
                        </PaginationItem>
                      </Pagination>
                      <Button
                        color="primary"
                        disabled={currentPage >= totalPages - 1 || isFetching}
                        onClick={handleNextPage}
                      >
                        Next
                      </Button>
                    </div>
                    <div className="text-right mt-2">
                      <small>
                        Page {currentPage + 1} of {totalPages}
                      </small>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>}
    </React.Fragment >
  );
};


export default GestionProducteurs;