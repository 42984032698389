import axios from "axios";
import config from "../helpers/config";

let isRefreshing = false;
let failedQueue = [];

// Function to handle requests that failed due to expired tokens
const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });
  failedQueue = [];
};

const axiosInstance = axios.create({
  baseURL: config.baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to include the Bearer token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");

    // Only add the Authorization header if the request path does not start with '/api/auth/'
    if (token && !config.url.startsWith("/api/auth/")) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle token expiration and retry requests
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If the error is 401, try refreshing the token
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (error.response.data.message && error.response.data.message.toLowerCase().includes("token expired")) {
        if (isRefreshing) {
          // Wait for the new token if a refresh request is already in progress
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          // Refresh the token using the refresh token
          const refreshToken = localStorage.getItem("refreshToken");
          const response = await axios.post(`${config.baseURL}/refresh`, { refreshToken });

          const { accessToken, refreshToken: newRefreshToken } = response.data;

          // Update tokens in local storage
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", newRefreshToken);

          // Set isRefreshing to false after the refresh
          isRefreshing = false;

          // Resolve the failed requests in the queue with the new token
          processQueue(null, accessToken);

          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axiosInstance(originalRequest);
        } catch (err) {
          // If refreshing the token fails, reject all queued requests
          processQueue(err, null);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          window.location.href = "/login?message=Session%20expired,%20please%20log%20in%20again";
          return Promise.reject(err);
        }
      } else {
        window.location.href = "/login?message=Unauthorized%20access";
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
