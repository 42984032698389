import axiosInstance from "./api_helper";

const API_BASE_URL = '/api/data'; // Define the base URL for master data API

export const fetchMasterData = async () => {
  try {
    const response = await axiosInstance.get(API_BASE_URL);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch master data');
  }
};