import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import appReducer from "./reducers";
import { rootSaga } from "saga-slice";
import appSaga from "./sagas";
import producteursSlice from "./producteursSlice";
import  authModule  from "./authSlice";
import userModule from "./userSlice";
import masterDataModule from "./dataSlice";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const modules = [masterDataModule, producteursSlice, authModule, userModule];

const store = createStore(
  appReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga(modules));
sagaMiddleware.run(appSaga);

export default store;
