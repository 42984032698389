import { createModule } from 'saga-slice';
import userService from '../services/userService';
import { call, put } from 'redux-saga/effects';

const userModule = createModule({
    name: 'user',
    initialState: {
      users: [],
      loading: false,
      error: null,
    },
    reducers: {
      list: (state) => {
        state.loading = true;
      },
      fetchedData: (state, payload) => {
        state.users = payload;
        state.loading = false;
      },
      fetchError: (state, action) => {
        state.loading = false;
        state.error = action.payload || 'An error occurred';
      },
      createUser: (state) => {
        state.loading = true;
        state.error = null;
      },
      updateUser: (state) => {
        state.loading = true;
        state.error = null;
      },
      createUserSuccess: (state) => {
        state.loading = false;
      },
      updateUserSuccess: (state) => {
        state.loading = false;
      },
      createUserError: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },
      updateUserError: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },
    },
    sagas: (A) => ({
      *[A.list]({ payload }) {
        try {
          const users = yield call(userService.getUsers, payload);
          yield put(A.fetchedData(users));
        } catch (error) {
          yield put(A.fetchError(error.message));
        }
      },
      *[A.createUser]({ payload }) {
        try {
          yield call(userService.createUser, payload);
          yield put(A.createUserSuccess());
        } catch (error) {
          yield put(A.createUserError(error.message));
        }
      },
      *[A.updateUser]({ payload }) {
        try {
          yield call(userService.updateUser, payload);
          yield put(A.updateUserSuccess());
          yield put(A.list({}));
        } catch (error) {
          yield put(A.updateUserError(error.message));
        }
      },
    }),
  });
  
  export default userModule;
  export const userActions = userModule.actions;