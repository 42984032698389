// MasterDataPage.jsx - UI Page to display the master data in tabs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Card, CardBody, Spinner, Container, Alert, Input, Table } from 'reactstrap';
import classnames from 'classnames';
import { masterDataActions } from '../../store/dataSlice';

const MasterDataPage = () => {
  const dispatch = useDispatch();
  const masterData = useSelector((state) => state.masterData.data);
  const isLoading = useSelector((state) => state.masterData.isLoading);
  const error = useSelector((state) => state.masterData.error);
  const [activeTab, setActiveTab] = useState("1");


  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [searchQueries, setSearchQueries] = useState({}); // Store search queries for each tab

  const handleSearchChange = (tabId, query) => {
    setSearchQueries((prevQueries) => ({
      ...prevQueries,
      [tabId]: query,
    }));
  };

  const filterData = (tabId, data, query) => {
    let list = data;
    if (tabId === '10') {
      list = data.map(d => ({ code: d.codePrefecture, description: d.nomPrefecture, codeRegion: d.codeRegion, nomRegion: d.nomRegion }))
    } else if (tabId === '11') {
      list = data.map(d => ({ code: d.codeSousPrefecture, description: d.nomSousPrefecture, codePerfecture: d.codePrefecture, nomPerfecture: d.nomPrefecture, }))
    } else if (tabId === '12') {
      list = data.map(d => ({ code: d.codeCulture, description: d.nomCulture }))
    } else if (tabId === '13') {
      list = data.map(d => ({ code: d.codeVariete, description: d.nomVariete, codeCulture: d.codeCulture }))
    } else if (tabId === '14') {
      list = data.map(d => ({ code: d.codeSection, description: d.nomSection, codeZone: d.codeZone, nomZone: d.nomZone }))
    }
    if (!query) return list;
    return list.filter((item) =>
      item.description.includes(query) || item.code.includes(query)
    );
  };

  const renderTable = (tabId, data) => {
    const searchQuery = searchQueries[tabId] || "";
    const filteredData = filterData(tabId, data, searchQuery);

    const getHeaders = () => {
      if (tabId === '4') {
        return <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
            <th>Code District</th>
            <th>Nom District</th>
          </tr>
        </thead>
      }
      else if (tabId === '8') {
        return <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
            <th>Code Sous-Préfecture</th>
            <th>Nom  Sous-Préfecture</th>
          </tr>
        </thead>
      }else if (tabId === '10') {
        return <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
            <th>Code Region</th>
            <th>Nom Region</th>
          </tr>
        </thead>
      } else if (tabId === '11') {
        return <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
            <th>Code Perfecture</th>
            <th>Nom Perfecture</th>
          </tr>
        </thead>
      } else if (tabId === '13') {
        return <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
            <th>Code Culture</th>
          </tr>
        </thead>
      } else if (tabId === '14') {
        return <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
            <th>Code Zone</th>
            <th>Nom Zone</th>
          </tr>
        </thead>
      } else {
        return <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
          </tr>
        </thead>
      }

    }

    const getData = () => {
      if (tabId === '10') {
        return <tbody>
          {filteredData.map((item) => (
            <tr key={item.code}>
              <td>{item.code}</td>
              <td>{item.description}</td>
              <td>{item.codeRegion}</td>
              <td>{item.nomRegion}</td>
            </tr>
          ))}
        </tbody>
      } else if (tabId === '11') {
        return <tbody>
          {filteredData.map((item) => (
            <tr key={item.code}>
              <td>{item.code}</td>
              <td>{item.description}</td>
              <td>{item.codePerfecture}</td>
              <td>{item.nomPerfecture}</td>
            </tr>
          ))}
        </tbody>
      } else if (tabId === '13') {
        return <tbody>
          {filteredData.map((item) => (
            <tr key={item.code}>
              <td>{item.code}</td>
              <td>{item.description}</td>
              <td>{item.codeCulture}</td>
            </tr>
          ))}
        </tbody>
      } else if (tabId === '14') {
        return <tbody>
          {filteredData.map((item) => (
            <tr key={item.code}>
              <td>{item.code}</td>
              <td>{item.description}</td>
              <td>{item.codeZone}</td>
              <td>{item.nomZone}</td>
            </tr>
          ))}
        </tbody>
      } else {
        return <tbody>
          {filteredData.map((item) => (
            <tr key={item.code}>
              <td>{item.code}</td>
              <td>{item.description}</td>
              <td>{item.codeParent}</td>
              <td>{item.nomParent}</td>
            </tr>
          ))}
        </tbody>
      }
    }

    return (
      <>
        <Input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => handleSearchChange(tabId, e.target.value)}
          className="mb-3"
        />
        <Table striped>
          {getHeaders()}
          {getData()}
        </Table>
      </>
    );
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>
      ) :
        <div className="page-content">
          <Container className="mt-2">
            <h3 >Données de base</h3>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggleTab("2");
                  }}
                >
                  Campagnes
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "12" })}
                  onClick={() => {
                    toggleTab("12");
                  }}
                >
                  Cultures Agricoles
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "13" })}
                  onClick={() => {
                    toggleTab("13");
                  }}
                >
                  Variétés semences
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "5" })}
                  onClick={() => {
                    toggleTab("5");
                  }}
                >
                  Type Pieces
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggleTab("1");
                  }}
                >
                  Districts
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "4" })}
                  onClick={() => {
                    toggleTab("4");
                  }}
                >
                  Regions
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "10" })}
                  onClick={() => {
                    toggleTab("10");
                  }}
                >
                  Prefectures
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "11" })}
                  onClick={() => {
                    toggleTab("11");
                  }}
                >
                  Sous-Prefectures
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "8" })}
                  onClick={() => {
                    toggleTab("8");
                  }}
                >
                  Localité
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "9" })}
                  onClick={() => {
                    toggleTab("9");
                  }}
                >
                  Zone
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "14" })}
                  onClick={() => {
                    toggleTab("14");
                  }}
                >
                  Section
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "7" })}
                  onClick={() => {
                    toggleTab("7");
                  }}
                >
                  Faitieres
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    toggleTab("3");
                  }}
                >
                  Cooperatives
                </NavLink>
              </NavItem>


              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "6" })}
                  onClick={() => {
                    toggleTab("6");
                  }}
                >
                  Ethnies
                </NavLink>
              </NavItem>


            </Nav>
            <TabContent activeTab={activeTab} className="mt-4">
              {isLoading && (
                <Row className="justify-content-center">
                  <Col xs="auto">
                    <Spinner color="primary" />
                  </Col>
                </Row>
              )}
              {error && <Alert color="danger">{error}</Alert>}
              <TabPane tabId="1">{renderTable("1", masterData?.districts || [])}</TabPane>
              <TabPane tabId="2">{renderTable("2", masterData?.campagnes || [])}</TabPane>
              <TabPane tabId="3">{renderTable("3", masterData?.cooperatives || [])}</TabPane>
              <TabPane tabId="4">{renderTable("4", masterData?.regions || [])}</TabPane>
              <TabPane tabId="5">{renderTable("5", masterData?.typePieces || [])}</TabPane>
              <TabPane tabId="6">{renderTable("6", masterData?.ethnies || [])}</TabPane>
              <TabPane tabId="7">{renderTable("7", masterData?.faitieres || [])}</TabPane>
              <TabPane tabId="8">{renderTable("8", masterData?.villages || [])}</TabPane>
              <TabPane tabId="9">{renderTable("9", masterData?.zones || [])}</TabPane>
              <TabPane tabId="10">{renderTable("10", masterData?.perfectures || [])}</TabPane>
              <TabPane tabId="11">{renderTable("11", masterData?.sousPerfectures || [])}</TabPane>
              <TabPane tabId="12">{renderTable("12", masterData?.culturesAgricoles || [])}</TabPane>
              <TabPane tabId="13">{renderTable("13", masterData?.varietes || [])}</TabPane>
              <TabPane tabId="14">{renderTable("14", masterData?.sections || [])}</TabPane>


            </TabContent>
          </Container>
        </div>}
    </React.Fragment>
  );
};

export default MasterDataPage;
