import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Container, Spinner, Input, FormGroup, Label, Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { userActions } from '../../store/userSlice';

const UserList = () => {
  const dispatch = useDispatch();
  const { users, loading, error } = useSelector((state) => state.user);
  const [searchCode, setSearchCode] = useState('');
  const [enabledFilter, setEnabledFilter] = useState('');

  useEffect(() => {
    dispatch(userActions.list({ code: searchCode, enabled: enabledFilter }));
  }, [dispatch, searchCode, enabledFilter]);

  const handleSearch = () => {
    dispatch(userActions.list({ code: searchCode, enabled: enabledFilter }));
  };

  if (loading) {
    return <Spinner color="primary" />;
  }

  if (error) {
    return <div className="text-danger">Error: {error}</div>;
  }

  const handleReset = () => {
    setSearchCode('');
    setEnabledFilter('');
  };

  return (
    <React.Fragment>
      {loading ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>
      ) :
        <div className="page-content">
          <Container fluid>
            <Row className="mb-4">
              <Col>
                <h2>Gestion des utilisateurs</h2>
              </Col>
            </Row>
            <Card className="mx-auto mb-4" >
              <CardBody>
                <div className="d-flex justify-content-center">
                  <FormGroup className="me-3">
                    <Label for="searchCode">Search by Code</Label>
                    <Input
                      type="text"
                      id="searchCode"
                      value={searchCode}
                      onChange={(e) => setSearchCode(e.target.value)}
                      placeholder="Enter user code"
                    />
                  </FormGroup>
                  <FormGroup className="me-3">
                    <Label for="enabledFilter">Filter by Status</Label>
                    <Input
                      type="select"
                      id="enabledFilter"
                      value={enabledFilter}
                      onChange={(e) => setEnabledFilter(e.target.value)}
                    >
                      <option value="">All</option>
                      <option value="true">Enabled</option>
                      <option value="false">Disabled</option>
                    </Input>
                  </FormGroup>
                  <Button color="primary" className="me-3 align-self-end" onClick={handleSearch}>Search</Button>
                  <Button color="secondary" className="align-self-end" onClick={handleReset}>Reset</Button>
                </div>
              </CardBody>
            </Card>
            <Link to="/gestion-utilisateurs/nouveau">
              <Button color="primary" className="mb-4">Create User</Button>
            </Link>
            <Card >
              <CardBody>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Nom</th>
                      <th>Prenom</th>
                      <th>Username</th>
                      <th>Email</th>
                      <th>Tel</th>
                      <th>Roles</th>
                      <th>Enabled</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <tr key={user.id}>
                        <td>{user.code}</td>
                        <td>{user.nom}</td>
                        <td>{user.prenom}</td>
                        <td>{user.username}</td>
                        <td>{user.email}</td>
                        <td>{user.tel}</td>
                        <td>{user.roles.join(', ')}</td>
                        <td>{user.enabled ? 'Enabled' : 'Disabled'}</td>
                        <td>
                          <Link to={`/user/edit/${user.id}`}>
                            <Button color="warning" size="sm">Edit</Button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Container>
        </div>}
    </React.Fragment>

  );
};

export default UserList;